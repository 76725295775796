<template>
  <Dialog v-model:visible="isDialogVisible" modal class="dialog" header="Buchungspositionen" @show="focusTreeFilter">
    <div class="card flex flex-wrap justify-content-center gap-5">
      <Tree
        v-if="bookingProjectTree.length > 0"
        ref="treeRef"
        v-model:expanded-keys="expandedKeys"
        :value="bookingProjectTree"
        :filter="true"
        filter-mode="lenient"
        class="w-full md:w-30rem"
        selection-mode="single"
        @filter="expandAll"
        @node-select="onSelectedLeaf"
      />
      <div v-else>
        <p>Keine Buchungspositionen vorhanden</p>
      </div>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from "primevue/dialog";
import Tree from "primevue/tree";
import { watch } from "vue";
import { BookingPositionDescription } from "@/data-types.ts";
import { useDialogBookingPosition } from "@/features/weekly/components/components/UseDialogBookingPosition.ts";

export interface DialogBookingPositionEmits {
  (e: "addSelectedBookingPosition", addSelectedBookingPosition: string): void;
}

export interface DialogBookingPositionProps {
  bookingPositionDescription: BookingPositionDescription[];
}

const isDialogVisible = defineModel<boolean>("dialogVisibility", { required: true });
const props = defineProps<DialogBookingPositionProps>();
const emit = defineEmits<DialogBookingPositionEmits>();

const { treeRef, bookingProjectTree, expandedKeys, focusTreeFilter, expandAll, onSelectedLeaf } = useDialogBookingPosition(props, emit, isDialogVisible);
watch(isDialogVisible, (currentIsDialogVisible) => {
  if (currentIsDialogVisible) {
    expandAll();
  }
});
</script>

<style scoped>
.dialog {
  width: 25rem;
}
</style>
