import { BookingDay, BookingPositionDescription } from "@/data-types.ts";
import { BookingPositionLeaf, BookingProjectNode } from "@/features/weekly/components/WeeklyTable.vue";

export function evaluateBookingPositionsForCurrentWeek(
  bookingPositionsDescriptionsByName: Map<string, BookingPositionDescription>,
  startOfWeek: BookingDay,
  endOfWeek: BookingDay,
): BookingPositionDescription[] {
  const availableBookingPositionsForCurrentWeek: BookingPositionDescription[] = [];
  bookingPositionsDescriptionsByName.forEach((desc: BookingPositionDescription) => {
    const isPositionInDay = startOfWeek.positionHours.some((pos) => pos.name === desc.name);
    if (isPositionInDay || desc.closed) return;
    if (!desc.isUserAllowedToBook) return;

    const changeableTill = desc.changeable_till ? new Date(desc.changeable_till) : null;
    const changeableFrom = desc.changeable_from ? new Date(desc.changeable_from) : null;
    const firstDayDate = startOfWeek.date;
    const lastDayDate = endOfWeek.date;

    if ((changeableTill && firstDayDate && changeableTill < firstDayDate) || (changeableFrom && lastDayDate && changeableFrom > lastDayDate)) return;
    availableBookingPositionsForCurrentWeek.push(desc);
  });
  return availableBookingPositionsForCurrentWeek;
}

export function createBookingProjectTreeForCurrentWeek(bookingPositionDescription: BookingPositionDescription[]): BookingProjectNode[] {
  const projects = Array.from(
    new Set(bookingPositionDescription.map((bookingPositionDescription: BookingPositionDescription) => bookingPositionDescription.project)),
  );

  let bookingProjectKey = 0;
  return projects
    .map((project: string) => {
      let bookingPositionLeafKey = 0;

      const bookingPositionLeafs = bookingPositionDescription
        .filter((bookingPositionDescription: BookingPositionDescription) => bookingPositionDescription.project === project)
        .map((bookingPositionDescription: BookingPositionDescription) => {
          return {
            key: `${bookingProjectKey}-${bookingPositionLeafKey++}`, // Use template literals to format the key
            icon: "",
            name: bookingPositionDescription.name,
            label: bookingPositionDescription.description,
          } as BookingPositionLeaf;
        })
        .sort((a, b) => a.label.localeCompare(b.label));

      return {
        key: `${bookingProjectKey++}`, // Increment booking project key after use
        icon: "pi pi-folder",
        label: project,
        children: bookingPositionLeafs,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label)) as BookingProjectNode[];
}
