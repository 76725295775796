<template>
  <transition :name="transitionName">
    <BaseCard v-show="componentVisible" class="baseCard">
      <WeekControl
        title="Arbeitsort"
        :calendar-exists="false"
        :year="startOfCurrentWeek.weekYear()"
        :week="startOfCurrentWeek.week()"
        :is-swipe-deactivated="isSwipeDeactivated"
        :disable-previous-week="loading"
        :disable-next-week="loading || isSameOrNextWeek(startOfCurrentWeek)"
        :is-forward-swipe-deactivated="loading || isSameOrNextWeek(startOfCurrentWeek)"
        @change-week-backward="changeWeek(-1)"
        @change-week-forward="changeWeek(1)"
      />
      <div v-if="!loading">
        <div class="p-my-2">
          <MultiSelect v-model="filter" :disabled="loading" :options="favorites" class="p-mr-2" style="width: 160px" />
        </div>
        <div v-for="booking in currentBookings" ref="dayLoopElement" :key="booking.date.toISOString()">
          <div>
            <WorkplaceDay
              :booking="booking"
              :desks="desks"
              :day-disabled="isDayDisabled(booking)"
              :public-holiday="findHolidayByDate(booking.date)"
              @workplace-change="onWorkplaceChange"
              @transportation-change="onTransportationChange"
              @parking-lot-change="onParkingLotChange"
            >
              <WorkplaceDesk
                v-if="isInOffice(booking) && !booking.isLoading"
                :booking="booking"
                :employees="employees"
                :desks="desks"
                :day-disabled="isDayDisabled(booking)"
                @deactivate-swipe="deactivateSwipe"
                @activate-swipe="activateSwipe"
                @desk-selected="submitBooking"
              />
            </WorkplaceDay>
          </div>
          <FavoritesHandler :disabled="isDayDisabled(booking)" :filter="filter" :booking="booking" :employees="employees" :desks="desks" />
        </div>
      </div>
      <progress-spinner v-else class="full-width" />
    </BaseCard>
  </transition>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import erpnextApi from "@/rest/ErpnextApi.ts";
import { CalendarPublicHoliday, Desk, erpNextTransportationProfile, WorkplaceBooking } from "@/data-types";
import { useToast } from "primevue/usetoast";
import WeekControl from "@/ui/WeekControl.vue";
import moment from "moment";
import ProgressSpinner from "primevue/progressspinner";
import BaseCard from "@/ui/BaseCard.vue";
import { isMobile } from "@/utils/Helper";
import WorkplaceDay from "@/features/workspace/components/WorkplaceDay.vue";
import FavoritesHandler from "@/features/workspace/components/FavoritesHandler.vue";
import WorkplaceDesk from "@/features/workspace/components/WorkplaceDesk.vue";
import { useRouter } from "vue-router";
import MultiSelect from "primevue/multiselect";
import { employeesNoteIfPresent, getStartOfWeekFromWeek } from "./utils/Workplace";
import { calculateWorkplaceRouterPath } from "@/utils/WorkplacePathCalculator";
import { showErrorToast, showSuccessToast } from "@/utils/ToastService.ts";
import { useStore } from "vuex";
import { RootState } from "@/store";

interface Props {
  week?: string;
  day?: string;
}

const props = withDefaults(defineProps<Props>(), {
  week: moment().weeks().toString(),
  day: moment().day().toString(),
});
const router = useRouter();
const toast = useToast();
const store = useStore<RootState>();

const componentVisible = ref(true);
const favorites = ref<string[]>([]);
const filter = ref(["Standard"]);
const startOfCurrentWeek = ref(getStartOfWeekFromWeek(Number(props.week)));
const isSwipeLeft = ref(true);
const currentBookings = ref<(WorkplaceBooking & { isLoading: boolean })[]>([]);
const loading = ref(true);
const dayLoopElement = ref();
const isSwipeDeactivated = ref(false);
const isItMobile = isMobile();
const transitionName = computed(() => {
  if (!isItMobile) {
    return "";
  } else {
    return isSwipeLeft.value ? "slide-left" : "slide-right";
  }
});
const desks = computed<Map<string, Desk>>(() => store.getters.getDesks);
const employees = computed(() => store.getters.getAllEmployeesAsMap);
const publicHolidays = computed<Map<string, CalendarPublicHoliday[]>>(() => store.getters.getPublicHolidays);
const employee = computed(() => store.getters.getEmployeesName);

watch(dayLoopElement, () => {
  const pos = document.querySelector("#" + props.day);
  pos && pos.scrollIntoView({ behavior: "smooth" });
});
watch(
  filter,
  (newVal) => {
    sessionStorage.setItem("filterOptions", JSON.stringify(newVal));
  },
  { deep: true },
);

onMounted(async () => {
  const storedFilters = sessionStorage.getItem("filterOptions");
  if (storedFilters) {
    const storedFiltersVal = JSON.parse(storedFilters);
    const filters = ref(storedFiltersVal);
    filter.value = filters.value;
  }
  await Promise.all([
    store.dispatch("fetchTransportationProfiles"),
    store.dispatch("fetchDesks"),
    store.dispatch("fetchFavoriteEmployees"),
    store.dispatch("fetchAllEmployees"),
    fetchCurrentPublicHolidays(),
    updateBookings(),
  ]);

  for (const key of store.getters.getFavoriteEmployees.keys()) {
    favorites.value.push(key);
    favorites.value.sort();
  }
  updateRoute(true);

  loading.value = false;
});

function updateRoute(initial?: boolean) {
  componentVisible.value = true;
  if (initial) router.replace({ params: { week: startOfCurrentWeek.value.week() } });
  else router.replace(calculateWorkplaceRouterPath(startOfCurrentWeek.value.week()));
}

function deactivateSwipe() {
  isSwipeDeactivated.value = true;
}

function activateSwipe() {
  isSwipeDeactivated.value = false;
}

function isInOffice(booking: WorkplaceBooking) {
  return booking.workplace === "office";
}

function onTransportationChange(booking: WorkplaceBooking) {
  booking.parking_lot_desired = store.getters.getTransportationProfiles.find(
    (x: erpNextTransportationProfile) => x.name === booking.transportation_profile,
  )?.parking_lot_desired;
  submitBooking(booking);
}

function findHolidayByDate(date: string | Date): CalendarPublicHoliday | undefined {
  const formattedDate = moment(date).format("MMYYYY");
  const holidaysOfMonthAndYear = publicHolidays.value.get(formattedDate);
  return holidaysOfMonthAndYear?.find((holiday) => {
    if (!holiday.date) return false;
    return new Date(holiday.date).getTime() === new Date(date).getTime();
  });
}

function onParkingLotChange(booking: WorkplaceBooking) {
  submitBooking(booking);
}

async function submitBooking(booking: WorkplaceBooking) {
  if (!booking.parking_lot_desired) booking.has_parking_lot = false;

  try {
    await erpnextApi.updateWorkplaceBooking(
      booking.date,
      booking.workplace,
      booking.desk,
      employeesNoteIfPresent(booking.notes, employee.value),
      store.getters.getEmployee?.is_external ? "" : booking.transportation_profile,
      booking.parking_lot_desired,
    );
    showSuccessToast(toast, "Arbeitsort bestätigt");
  } catch {
    showErrorToast(toast, "Arbeitsort konnte nicht geändert werden");
  }
}

async function bookDeskSuggestedByBackend(booking: WorkplaceBooking & { isLoading: boolean }) {
  const defaultTransportation = store.getters.getUserProfile.default_transportation;
  booking.isLoading = true;
  const transportationProfile = store.getters.getTransportationProfiles.find((profile: erpNextTransportationProfile) => profile.name === defaultTransportation);
  booking.parking_lot_desired = transportationProfile?.parking_lot_desired;
  booking.transportation_profile = defaultTransportation;

  try {
    const data = await erpnextApi.getDeskStatus(booking.date);
    booking.desk = data.find((desk) => desk.favorite)?.name;
    await submitBooking(booking);
  } finally {
    booking.isLoading = false;
  }
}

function cancelDeskBooking(booking: WorkplaceBooking) {
  booking.desk = "";
  submitBooking(booking);
}

function onWorkplaceChange(booking: WorkplaceBooking) {
  booking.transportation_profile = undefined;
  if (booking.workplace === "office") {
    bookDeskSuggestedByBackend(booking as any);
  } else {
    cancelDeskBooking(booking);
  }
}

function isSameOrNextWeek(week: moment.Moment) {
  return moment().add(1, "days").add(1, "week").isSameOrBefore(week, "week");
}

async function changeWeek(week: number) {
  loading.value = true;
  startOfCurrentWeek.value = startOfCurrentWeek.value.add(week, "weeks");
  await fetchCurrentPublicHolidays();
  if (isItMobile) {
    isSwipeLeft.value = week === 1;
    componentVisible.value = false;
    await updateBookings();
    loading.value = false;
    updateRoute();
  } else {
    await updateBookings();
    loading.value = false;
    updateRoute();
  }
}

function isDayDisabled(booking: WorkplaceBooking) {
  return !!booking.absence_reason || moment().isAfter(booking.date, "day");
}

async function fetchCurrentPublicHolidays() {
  const yearStart = startOfCurrentWeek.value.clone().format("YYYY");
  const monthStart = startOfCurrentWeek.value.clone().format("MM");
  const yearEnd = startOfCurrentWeek.value.clone().endOf("isoWeek").format("YYYY");
  const monthEnd = startOfCurrentWeek.value.clone().endOf("isoWeek").format("MM");

  if (yearStart + monthStart !== yearEnd + monthEnd)
    await store.dispatch("fetchPublicHolidays", {
      year: yearEnd,
      month: monthEnd,
    });

  await store.dispatch("fetchPublicHolidays", { year: yearStart, month: monthStart });
}

async function updateBookings() {
  return erpnextApi.getWorkplaceBookings(startOfCurrentWeek.value.toDate(), 5).then((workplaceBookings) => {
    currentBookings.value = workplaceBookings.map((booking) => ({ ...booking, isLoading: false }));
  });
}
</script>

<style scoped>
.slide-left-enter-active,
.slide-left-leave-active {
  transition: transform 0.5s ease;
}

.slide-left-enter-from,
.slide-left-leave-to {
  transform: translateX(-100%);
}

.slide-right-enter-active,
.slide-right-leave-active {
  transition: transform 0.5s ease;
}

.slide-right-enter-from,
.slide-right-leave-to {
  transform: translateX(100%);
}
</style>
