<template>
  <div class="form-control">
    <CalendarInput v-model:validation="validation" :is-loading="isLoading" :submitted="submitted" :is-locked="isCalendarInputLocked" />
  </div>
  <div class="form-control">
    <label for="reason">Kommentar</label>
    <Textarea
      v-model="validation.reason.$model"
      :auto-resize="true"
      :class="{ 'p-invalid': validation.reason.$invalid && submitted }"
      :disabled="isLoading"
      class="full-width"
    />
    <small v-if="validation.reason.$invalid && submitted" class="p-error">
      {{ validation.reason.maxLengthValue.$message }}
    </small>
  </div>
  <div>
    <div class="checkbox-div">
      <div>
        <Checkbox v-model="isApprovedByTeamMembers" :binary="true" input-id="AckTeam" name="AckTeam" />
      </div>

      <label class="holiday-cb-label" for="AckTeam">Ich habe den Urlaub mit dem Projekt(team) und anderen relevanten Personen abgestimmt</label>
    </div>
  </div>
  <div class="p-d-flex p-jc-center p-mt-4">
    <Button
      :disabled="isLoading || !isApprovedByTeamMembers.valueOf() || validation.$invalid"
      :icon="isLoading ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
      :label="isLoading ? 'Einen Moment' : 'Absenden'"
      class="primaryButton button-placeholder-width"
      icon-pos="right"
      @click="submitHolidayForm()"
    />
  </div>
  <div class="p-d-flex p-jc-center">
    <p class="color-grey">Status wechselt nach 48 Stunden automatisch auf eingetragen.</p>
  </div>
</template>

<script setup lang="ts">
import Button from "primevue/button";
import Textarea from "primevue/textarea";
import Checkbox from "primevue/checkbox";
import CalendarInput from "@/ui/CalendarInput.vue";
import { useHolidayForm } from "@/features/absence/components/holidayForm/UseHolidayForm.ts";

export interface HolidayFormProps {
  isCalendarInputLocked: boolean;
  startAbsence: Date | null;
  endAbsence: Date | null;
}

export interface HolidayFormEmits {
  (e: "submitted"): void;
}

const props = withDefaults(defineProps<HolidayFormProps>(), {
  isCalendarInputLocked: false,
  startAbsence: null,
  endAbsence: null,
});

const emit = defineEmits<HolidayFormEmits>();
const { validation, isApprovedByTeamMembers, submitHolidayForm, submitted, isLoading } = useHolidayForm(props, emit);
</script>

<style scoped>
.holiday-cb-label {
  display: table-cell;
  padding-left: 0.7rem;
  user-select: none;
  font-weight: normal;
}

.form-control {
  margin: 1rem 0;
}

.checkbox-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

label {
  font-weight: bold;
  display: block;
  width: 100%;
  margin-bottom: 0.25rem;
}
</style>
