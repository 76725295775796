<template>
  <div class="form-control">
    <CalendarInput v-model:validation="validation" :is-loading="isLoading" :submitted="submitted" :is-locked="isCalendarInputLocked" />
    <div v-if="isSickNoteRequired" class="form-control">
      <div class="is-digital-sick-note-required">
        <Checkbox
          v-model="validation.isDigitalSickNoteChecked.$model"
          label="Krankmeldung vorhanden"
          :binary="true"
          input-id="digitalSickNote"
          :class="{ 'p-invalid': validation.isDigitalSickNoteChecked.$invalid && submitted }"
        />
        <label for="digitalSickNote" class="digital-sick-note-checkbox-label">Ich habe eine digitale Krankmeldung</label>
      </div>
      <small v-if="validation.isDigitalSickNoteChecked.$invalid && submitted" class="p-error">
        {{ validation.isDigitalSickNoteChecked.required.$message }}
      </small>
    </div>
    <label for="reason">Kommentar</label>
    <Textarea
      v-model="validation.reason.$model"
      :auto-resize="true"
      :class="{ 'p-invalid': validation.reason.$invalid && submitted }"
      :disabled="isLoading"
      class="full-width"
    />
    <small v-if="validation.reason.$invalid && submitted" class="p-error">
      {{ validation.reason.maxLengthValue.$message }}
    </small>
  </div>
  <div class="form-control">
    <label for="file">Bescheinigung</label>
    <div class="file-upload">
      <Textarea v-model="fileSelected" :readonly="'true'" :auto-resize="true" class="height"></Textarea>
      <FileUpload
        :custom-upload="true"
        :disabled="isFileUploading || isLoading"
        :max-file-size="1000000"
        :auto="true"
        accept=".pdf"
        choose-label="PDF anhängen "
        mode="basic"
        @select="onFileSelect"
      />
      <Button v-if="fileSelected" type="button" class="p-button-text p-button-danger" @click="clearFileSelect">
        <i class="pi pi-trash"></i>
      </Button>
    </div>
  </div>
  <div class="p-d-flex p-jc-center">
    <Button
      :disabled="isLoading"
      :icon="isLoading ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
      :label="isLoading ? 'Einen Moment' : 'Absenden'"
      class="primaryButton button-placeholder-width"
      icon-pos="right"
      @click="submitIllnessForm(!validation.$invalid)"
    />
  </div>
  <div class="p-d-flex p-jc-center">
    <p class="color-grey">Status wechselt nach 48 Stunden automatisch auf eingetragen.</p>
  </div>
</template>

<script setup lang="ts">
import Button from "primevue/button";
import FileUpload from "primevue/fileupload";
import Textarea from "primevue/textarea";
import Checkbox from "primevue/checkbox";
import CalendarInput from "@/ui/CalendarInput.vue";
import { useIllnessForm } from "@/features/absence/components/illnessForm/UseIllnessForm.ts";

export interface IllnessFormProps {
  isCalendarInputLocked: boolean;
  startAbsence: Date | null;
  endAbsence: Date | null;
}

export interface IllnessFormEmits {
  (e: "submitted"): void;
}

const props = withDefaults(defineProps<IllnessFormProps>(), {
  isCalendarInputLocked: false,
  startAbsence: null,
  endAbsence: null,
});

const emit = defineEmits<IllnessFormEmits>();

const { fileSelected, clearFileSelect, onFileSelect, validation, isFileUploading, submitIllnessForm, submitted, isLoading, isSickNoteRequired } =
  useIllnessForm(props, emit);
</script>

<style scoped>
.form-control {
  margin: 1rem 0;
}

.file-upload {
  display: flex;
  align-items: center;
  max-width: 100%;
}

.height {
  margin-right: 1rem;
  max-height: 2.4rem;
  flex: 1;
  white-space: nowrap;
}

label {
  font-weight: bold;
  display: block;
  width: 100%;
  margin-bottom: 0.25rem;
}

.is-digital-sick-note-required {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.digital-sick-note-checkbox-label {
  display: table-cell;
  padding-left: 0.7rem;
  user-select: none;
  font-weight: normal;
}
</style>
