<template>
  <ProgressSpinner v-if="!loadedContent" class="p-d-flex p-jc-center" />
  <div v-if="isEvent" class="eventWidgetContainer">
    <div class="eventTitle">Events im Büro</div>
    <div v-for="(message, index) in eventMessages" :key="index" class="eventText">
      {{ message }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import erpnextApi from "@/rest/ErpnextApi.ts";
import ProgressSpinner from "primevue/progressspinner";
import { Note } from "@/data-types.ts";

const eventMessages = ref<string[]>([]);
const date = new Date();
const workplaceBookings = ref();
const loadedContent = ref(false);
const isEvent = ref(false);

async function getEvent() {
  workplaceBookings.value = await erpnextApi.getWorkplaceBookings(date, 1);
  if (workplaceBookings.value?.[0]?.notes?.length > 0) {
    eventMessages.value = workplaceBookings.value[0].notes.map((note: Note) => note.note);
    isEvent.value = true;
  }
  loadedContent.value = true;
}

onMounted(() => {
  getEvent();
});
</script>

<style scoped>
.eventWidgetContainer {
  margin: 1rem;
  padding: 0.5rem;
  background-color: var(--greystep);
  border-radius: 5px;
}

.eventTitle {
  font-size: 24px;
  border-bottom: 1px solid var(--white);
}

.eventText {
  font-size: 23px;
  font-weight: bold;
  margin-top: 1rem;
  color: var(--primary);
}

@media (max-width: 700px) {
  .eventTitle {
    font-size: 20px;
  }

  .eventText {
    font-size: 20px;
  }
}
</style>
