import { BookingDay, BookingDayPosition } from "@/data-types";
import moment from "moment";

function chooseState(entry: BookingDay) {
  if (entry.error) {
    entry.fixed = entry.error;
  } else if (entry.booked && entry.state === "Absent") {
    entry.fixed = "Als abwesend gebucht";
  } else if (entry.state === "On Leave") {
    entry.fixed = "Urlaub";
  } else if (entry.state === "Sick") {
    entry.fixed = "Krank " + entry.totalWorkHours.toFixed(2) + "h";
  } else if (entry.state === "Future") {
    entry.fixed = "Zukünftige Tage nicht buchbar";
  }
}

export function prepareWeek(bookingDays: BookingDay[]): BookingDay[] {
  const bookingDayPositionNames: string[] = [...new Set(bookingDays.flatMap((x: BookingDay) => x.positionHours).map((x: BookingDayPosition) => x.name))];
  for (const bookingDay of bookingDays) {
    bookingDay.currentDate && (bookingDay.date = new Date(bookingDay.currentDate));
    chooseState(bookingDay);

    const newHoursList = [];
    for (const bookingDayPositionName of bookingDayPositionNames) {
      const hourEntry = bookingDay.positionHours.find((x: any) => x.name == bookingDayPositionName);
      if (hourEntry) newHoursList.push(hourEntry);
      else
        newHoursList.push({
          name: bookingDayPositionName,
          hours: "00:00",
          comment: "",
        });
    }
    bookingDay.positionHours = newHoursList;
  }
  return bookingDays;
}

export function getColorOfActiveButton(active: boolean, value: number) {
  if (active && value === 5) return "var(--pink)";
  else if (active) return "var(--primary)";
  else return "var(--gray-500)";
}

// Helper.ts
export function calculateDayHash(date: Date | undefined) {
  return moment(date).format("yyyy-MM-DD");
}

export function randomInRange(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

export function isBookingDayRelevant(bookingDay: BookingDay): boolean {
  const { state, booked } = bookingDay;
  const isDaySickOrInFuture = state === "On Leave" || state === "Future";
  if (isDaySickOrInFuture) return false;
  return !booked || state !== "Absent";
}

export function calculateYearFromErpWeek(erpWeek: BookingDay[]) {
  // to get the correct year we have to know when a new year starts in the week, see
  // https://en.wikipedia.org/wiki/ISO_8601 the week with the starting year's first Thursday in it (the formal ISO definition)
  return erpWeek[3]?.date?.getFullYear();
}
