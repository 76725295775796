import { HolidayFormEmits, HolidayFormProps } from "@/features/absence/components/holidayForm/HolidayForm.vue";
import { reactive, ref } from "vue";
import { useToast } from "primevue/usetoast";
import { helpers, maxLength, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { calculateDurationBetweenDates, handleAbsenceSubmission, isDateRangeValid } from "@/features/absence/utils/Absence.ts";
import erpnextApi from "@/rest/ErpnextApi.ts";
import { formatDateToERP } from "@/utils/Helper.ts";
import { showErrorToast, showSuccessToast } from "@/utils/ToastService";

export interface Holiday {
  dateRange: Date[];
  reason: string;
}

export function useHolidayForm(props: HolidayFormProps, emit: HolidayFormEmits) {
  const submitted = ref<boolean>(false);
  const isLoading = ref<boolean>(false);

  const toast = useToast();

  const isApprovedByTeamMembers = ref<boolean>(false);

  const holidayRequestData = reactive<Holiday>({
    dateRange: [props.startAbsence, props.endAbsence].filter(Boolean) as Date[],
    reason: "",
  });

  const holidayValidationRules = {
    dateRange: { required: helpers.withMessage("Bitte einen Zeitraum angeben", required) },
    reason: { maxLengthValue: helpers.withMessage("Bitte nutzen Sie maximal 100 Zeichen.", maxLength(100)) },
  };

  const validation = useVuelidate(holidayValidationRules, holidayRequestData);

  async function submitHolidayForm(): Promise<void> {
    submitted.value = true;
    isLoading.value = true;

    if (validation.value.$invalid) {
      isLoading.value = false;
      return;
    }

    if (!isDateRangeValid(holidayRequestData.dateRange)) {
      isLoading.value = false;
      return;
    }

    try {
      await handleAbsenceSubmission(holidayRequestData.dateRange, submitHolidayRequest);
      await handleSuccessfulHolidayFormSubmission();
    } catch (error: any) {
      handleSubmissionError(error);
    } finally {
      finalizeHolidayRequestSubmission();
    }
  }

  function finalizeHolidayRequestSubmission(): void {
    isLoading.value = false;
    submitted.value = false;
  }

  async function submitHolidayRequest(startDate: Date, endDate: Date): Promise<void> {
    await erpnextApi.postHolidayRequest(formatDateToERP(startDate), calculateDurationBetweenDates(startDate, endDate), holidayRequestData.reason);
  }

  async function resetHolidayForm(): Promise<void> {
    holidayRequestData.dateRange = [];
    holidayRequestData.reason = "";
    isApprovedByTeamMembers.value = false;
  }

  async function handleSuccessfulHolidayFormSubmission(): Promise<void> {
    showSuccessToast(toast, "Urlaub erfolgreich eingereicht");
    await resetHolidayForm();
    emit("submitted");
  }

  function handleSubmissionError(error: unknown): void {
    const errorMessage = (error as Error)?.message || "Ein unbekannter Fehler ist aufgetreten";
    showErrorToast(toast, errorMessage);
  }

  return {
    validation,
    isApprovedByTeamMembers,
    submitHolidayForm,
    submitted,
    isLoading,
  };
}
