import { ComponentPublicInstance, computed, ModelRef, nextTick, ref, useTemplateRef } from "vue";
import { createBookingProjectTreeForCurrentWeek } from "@/features/weekly/utils/WeeklyTable.ts";
import { TreeNode } from "primevue/treenode";
import { DialogBookingPositionEmits, DialogBookingPositionProps } from "@/features/weekly/components/components/DialogBookingPosition.vue";

interface ExpandedKeys {
  [key: string]: boolean;
}
export function useDialogBookingPosition(props: DialogBookingPositionProps, emit: DialogBookingPositionEmits, isDialogVisible: ModelRef<boolean, string>) {
  const bookingProjectTree = computed(() => createBookingProjectTreeForCurrentWeek(props.bookingPositionDescription));
  const expandedKeys = ref({} as ExpandedKeys);
  // hint https://vuejs.org/guide/typescript/composition-api#typing-component-template-refs
  const treeRef = useTemplateRef<ComponentPublicInstance>("treeRef");

  function onSelectedLeaf(treeNode: TreeNode) {
    const selectedLeafName = bookingProjectTree.value
      .flatMap((bookingProject) => bookingProject.children)
      .find((bookingPosition) => bookingPosition.key === treeNode.key)?.name;
    if (!selectedLeafName) return;
    emit("addSelectedBookingPosition", selectedLeafName);
    isDialogVisible.value = false;
  }

  async function focusTreeFilter(): Promise<void> {
    await nextTick(() => {
      const treeElement = treeRef.value?.$el;
      const filterInput = treeElement?.querySelector("input.p-tree-filter");
      if (filterInput) {
        filterInput.focus();
      }
    });
  }

  function expandAll(): void {
    for (const node of bookingProjectTree.value) {
      expandedKeys.value[node.key] = true;
    }
  }

  return {
    bookingProjectTree,
    expandedKeys,
    treeRef,
    onSelectedLeaf,
    focusTreeFilter,
    expandAll,
  };
}
